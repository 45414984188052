.Footer {
    background: #ffffff 0 0 no-repeat padding-box;
    border-top: 1px solid #bbc0c1;
}

.CollapsedBadge {
    margin-left: 2px !important;
    margin-top: -4px !important;
    z-index: 99;
}

.IconPadding {
    padding: 4px !important;
}

.AcknowledgeButton {
    margin-top: -4px !important;
    vertical-align: top;
}

.PhonetoolButton {
    height: inherit !important;
    padding: 0 !important;
}

.RoundedImage {
    border-radius: 4px;
}

.Blue {
    color: #00a8e1;
}

.BlueCheck {
    color: #077398;
}

.Orange {
    color: #cb330b;
    text-decoration: underline;
}

.Red {
    color: #cc0c39 !important;
}

.RedLink a {
    color: #cc0c39 !important;
}

.RedLink a:hover {
    color: #cc0c39 !important;
}

.RemovePadding {
    padding: 0 !important;
}

.Clickable {
    cursor: pointer;
}

.TextBoxWrapper {
    padding-left: 32px !important;
}

.AlignWithAlert {
    padding-left: 24px !important;
}

.UpdateHeader {
    width: 100%;
    margin: 4px;
}

.RemoveLeftPadding {
    margin-left: -36px !important;
}

.SmallMargin {
    margin-right: 4px !important;
}

.LoadingMessage {
    display: grid;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0%;
    right: 0%;
    bottom: 0%;
    top: 0%;
    grid-template-columns: 150px 150px 150px 15px;
    grid-template-rows: auto 30px 30px auto;
    grid-template-areas:
        ". . . ."
        "text       text        text        text"
        "loadingbar loadingbar  loadingbar  cancel"
        ". . . .";
    background-color: rgba(255, 255, 255, 0.5);
}

.TextBar {
    grid-area: text;
}

.LoadingBar {
    grid-area: loadingbar;
}

.CancelIcon {
    grid-area: cancel;
}